import {
	FC,
	ReactNode,
	useCallback,
	CSSProperties
} from "react"
import Button from "react-bootstrap/Button"
import Employee from "../data/models/Employee"
// import EmployeePhoto from "./EmployeePhoto"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/pro-solid-svg-icons"
import { ButtonVariant } from "react-bootstrap/esm/types"
import { isDesktop } from "../data/styleHelper"
import style from "./style/EmployeeRecord.module.css"

interface Props {
	employee: Employee
	onClick: (employeeId: number) => void
	customEmployeeName?: (employee: Employee) => ReactNode
	customLastRow?: ReactNode
	variant?: ButtonVariant
	id?: string,
}

const EmployeeRecord: FC<Props> = ({
	employee,
	onClick,
	customLastRow,
	customEmployeeName,
	variant,
	id }) => {

	const ellipsisOverflow: CSSProperties = {
		maxWidth: isDesktop
			? `calc((100vw - 3rem) / 2 - 11rem)`
			: `calc(100vw - 13rem)`
	}

	const onEmployeeClick = useCallback(() => onClick(employee.Id), [employee.Id, onClick])

	return <Button size="lg" onClick={!customLastRow ? onEmployeeClick : undefined}
		className={style.removedStylesBootstrapButton}
		{...({
			...(id ? { id } : {}),
			...(variant ? { variant } : {})
		})}>
		<span className={style.container}>
			<span className={style.photo}>
				{/* <EmployeePhoto employee={employee} /> */}
			</span>
			<span className={style.employee}>
				<span className={style.data}>
					<span className={style.ellipsisOverflow} style={ellipsisOverflow}>
						{customEmployeeName
							? customEmployeeName(employee)
							: `${employee.Surname} ${employee.Name}`}
					</span>
					<span className={`${style.description} ${style.ellipsisOverflow}`} style={ellipsisOverflow}>
						{employee.Department}
					</span>
					{customLastRow
						? customLastRow
						: <span className={`${style.description} ${style.ellipsisOverflow}`} style={ellipsisOverflow}>
							{employee.Room?.Site} {employee.Room?.Name}
						</span>}
				</span>
				<span className={style.toEmployee} onClick={customLastRow ? onEmployeeClick : undefined}
					title="Zum Mitarbeiter">
					<FontAwesomeIcon icon={faChevronRight} size="1x" />
				</span>
			</span>
		</span>
	</Button>
}

export default EmployeeRecord
